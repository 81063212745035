// import domain from '@/service/domain'
import Cookies from 'js-cookie';
import { env } from "md-base-tools/env";
import { throttle } from "@/utils/tools";
import { mapState } from "vuex";
import { showDialog } from 'vant';
import { cardDeliverGoods } from "@/api";
import { mdToast } from '../../utils/tools';
export default {
  props: ['show', 'card_total', 'addressList', 'addressItem', 'first_relation', 'template_info',
  // 运费模板
  'template_info_list',
  // 运费模板详情国际版
  'loc'],
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    deliverVisible: {
      get() {
        return this.show;
      },
      set() {
        this.$emit('close');
      }
    },
    select_id() {
      let key = this.first_relation;
      return sessionStorage.getItem(key);
    },
    postage_detail() {
      // 运费详情
      if (this.template_info.fee > 0) {
        if (this.template_info.card_number == 0) {
          return `¥${this.template_info.fee}`;
        } else {
          if (this.card_total < this.template_info.card_number) {
            return `¥${this.template_info.fee} (满${this.template_info.card_number}张包邮)`;
          } else {
            return '包邮';
          }
        }
      } else {
        return '包邮';
      }
    },
    postage() {
      if (this.template_info.fee > 0) {
        if (this.template_info.card_number == 0) {
          return this.template_info.fee;
        } else {
          if (this.card_total < this.template_info.card_number) {
            return this.template_info.fee;
          } else {
            return 0;
          }
        }
      } else {
        return 0;
      }
    },
    template_info_loc() {
      if (this.template_info_list && Array.isArray(this.template_info_list) && this.template_info_list.length > 0) {
        let filteredLoc = this.template_info_list.filter(item => item.country_code === 'th' || item.country_code === 'cn');
        let uniqueCountryCodes = [...new Set(filteredLoc.map(item => item.country_code))];
        return uniqueCountryCodes;
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      closeIcon: 'https://p6.moimg.net/path/dst_project/1063753/202211/0310/4400/22110344008lmwGOxRgXAKv73Jdjvrbd12V0p5j7.png',
      env,
      iframe: false,
      html: '',
      pay_resource: 'weixin_mina',
      iframeUrl: location.origin + "/luckycards/fastsell/openhtml"
    };
  },
  methods: {
    clickfn(type) {
      this.pay_resource = type;
    },
    // 切换地址
    goToAddressList() {
      sessionStorage.setItem('hasDeliverGood', 'changeAddress');
      this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.origin + location.pathname)}`);
    },
    closeActionSheet() {
      this.iframe = false;
      this.html = '';
      this.deliverVisible = false;
    },
    clearOption() {
      let key = this.first_relation;
      return sessionStorage.removeItem(key);
    },
    // 支付并发货------------------
    deliverGoods: throttle(async function () {
      if (this.loc != 'CN') {
        showDialog({
          title: "提示",
          message: "仅支持中国大陆发货，发往其他地区请联系客服"
        }).then(() => {});
        return false;
      }
      if (this.card_total <= 0) {
        mdToast('请选择要发货的卡包');
        return false;
      }
      if (JSON.stringify(this.addressItem) == '{}') {
        mdToast('请选择卡片发货地址');
        return false;
      }
      // this.getOption()
      let platform = '';
      if (this.env.isWeixin()) {
        platform = 'weixin';
      } else if (this.env.isWeibo()) {
        platform = 'weibo';
      } else {
        platform = 'alipay';
      }
      if (!this.env.isWeixin() && !this.env.isWeibo()) {
        if (this.pay_resource == 'weixin_mina') {
          platform = 'weixin';
        } else {
          platform = 'alipay';
        }
      }
      let channel = 'pc';
      if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
        channel = 'miniprogram';
      } else if (this.env.isWeixin()) {
        channel = 'weixin';
      } else if (this.env.isModianIos() || this.env.isModianAndroid() || this.env.isModianIosNew() || this.env.isMobile()) {
        channel = 'wap';
      } else {
        channel = 'pc';
      }
      let option = {
        postage: this.postage,
        platform: platform,
        channel: channel,
        address: JSON.stringify(this.addressItem),
        select_id: this.select_id
      };
      const res = await cardDeliverGoods(option, {});
      if (res.status == 0) {
        if (!res.data) {
          // this.PaySuccessBack();
          mdToast("发货单已提交");
          this.clearOption();
          setTimeout(() => {
            location.reload();
            // location.href = `${domain.wap}/luckycards/container`;
          }, 300);
          return false;
        }
        if (!env.isWeixin() && !env.isWeibo() && this.pay_resource == 'weixin_mina') {
          window.location.href = res.data.data;
        } else if (this.env.isWeixin() || this.env.isWxApp()) {
          if (sessionStorage.getItem("miniProgram") || this.env.isWxApp()) {
            // eslint-disable-next-line no-undef
            wx.miniProgram.navigateTo({
              url: "/pages/payPages/pay_h5/pay_h5?type=container&hasDataPay=" + encodeURIComponent(JSON.stringify(res.data.data))
            });
          } else {
            const json = {
              appId: res.data.data.appid || res.data.data.appId,
              timeStamp: res.data.data.timestamp,
              nonceStr: res.data.data.noncestr,
              package: res.data.data.package,
              signType: res.data.data.signtype,
              paySign: res.data.data.paysign
            };
            this.callWeixinPay(json);
          }
        } else {
          if (this.env.isModianIos() || this.env.isModianAndroid()) {
            this.iframe = true;
            setTimeout(() => {
              let iframeWin = document.querySelectorAll("iframe")[0].contentWindow;
              document.querySelectorAll("iframe")[0].style.cssText += "position: fixed;display: block;border: 0px;top: 44px;left: 0px;width: 100vw;z-index: -999;height: 0;";
              iframeWin.postMessage({
                data: res.data.data,
                type: "alpay",
                dataType: res.data.dataType
              }, "*");
            }, 100);
          } else if (res.data.dataType == "URL") {
            window.location.href = res.data.data;
          } else if (res.data.dataType == "FORMDATA") {
            this.html = res.data.data;
            setTimeout(() => {
              document.forms[0].submit();
            });
          } else {
            window.location.href = res.data.data;
          }
        }
        this.$emit('changeIfPayDown', true);
      } else {
        mdToast(res.message);
      }
    }),
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              this.$emit('changeIfPayDown', false);
            } else {
              mdToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    PaySuccessBack() {
      mdToast("发货单已提交");
      // this.$emit('changeIfPayDown', true)
      this.clearOption();
      setTimeout(() => {
        this.deliverVisible = false;
      }, 300);
    },
    tabThailand() {
      window.location.href = 'https://m.hi-mystery.com/luckycards/container?loginToken=' + Cookies.get('MDUSERTOKEN');
    }
  }
};
import { env } from "md-base-tools/env";
// import domain from '@/service/domain'
import { mapState } from "vuex";
import Error from "@@/common/Error.vue";
import lowerdev from "@/service/lowerdev";
import { getFirstLevelList, getSecondLevelList, GetSelectActivityCardNums, getAddressList, getFreightTemplateInfo } from "@/api";
import { List, Icon } from 'vant';
import CompPay from "@@/luckycards/containerPay.vue";
import CompCards from "@@/luckycards/containerDetail.vue";
import { mdToast } from "../../utils/tools";
export default {
  components: {
    Error,
    List,
    CompPay,
    CompCards,
    Icon
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    card_total() {
      let num = 0;
      for (let key in this.numObj) {
        num += this.numObj[key] || 0;
      }
      return num;
    }
  },
  data() {
    return {
      env,
      showError: false,
      showMessage: "",
      loading: true,
      miniProgram: "",
      nav: [{
        txt: '寄存中',
        status: 0
      }, {
        txt: '发货单',
        status: 1
      }],
      card_status: 0,
      page: 1,
      timer: null,
      total: 1,
      page_size: 4,
      card_list: [],
      activity_ids: [],
      //全选数组
      order_id_list: [],
      deliverVisible: false,
      addressList: [],
      // 地址列表
      addressItem: {},
      // 选中地址
      openwxmina: false,
      is_next: false,
      ifPayDown: false,
      cardDetailVisible: false,
      card_detail_total: 0,
      card_detail_list: [],
      hasCheckFirst: '',
      showCards: false,
      //展示选卡弹层
      second_relation: '',
      activity_name: '',
      activity_id: '',
      select_id: '',
      numObj: {},
      canChooseMore: false,
      select_total: 0,
      template_map: {},
      // 运费模板
      template_info: {},
      // 运费模板详情
      template_info_list: {},
      // 运费模板详情国际版
      relation_type: '',
      // 已选卡包类型
      address_loc: ''
    };
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = 0;
    window.userHeader.titleText("我的卡包");
    // 参数为true时，不请求num接口

    // 获取收货地址
    await this.getAddress();
    await this.getInfo();
    this.miniProgram = sessionStorage.getItem("miniProgram") == "weixin";
    const that = this;
    document.addEventListener("visibilitychange", async function () {
      // 用户息屏、或者切到后台运行 （离开页面）
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      }
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        console.log("页面可见");
        if (that.openwxmina || that.ifPayDown) {
          mdToast('发货单已提交');
          that.ifPayDown = false;
          setTimeout(() => {
            location.reload();
            // location.href = `${domain.wap}/luckycards/container`;
          }, 300);
        } else if (that.env.isWxApp() || that.env.isModianAndroid() || that.env.isModianIos()) {
          await this.getAddress();
          this.openDeliver();
        }
      }
    });
  },
  unmounted() {
    // eslint-disable-next-line no-undef
    userHeader.backnav.style.top = "1.6rem";
  },
  methods: {
    async closePay() {
      this.select_total = 0;
      let key = this.hasCheckFirst;
      if (sessionStorage.getItem(key)) {
        let index = this.card_list.findIndex(item => item.first_relation == this.hasCheckFirst);
        if (index > -1) {
          await this.getNums(this.card_list[index].list, key);
        }
      }
      this.deliverVisible = false;
    },
    changeIfPayDown(val) {
      this.ifPayDown = val;
    },
    // 关闭卡包详情
    closeDetail(val) {
      this.showCards = val;
      let key = this.hasCheckFirst;
      if (sessionStorage.getItem(key)) {
        let index = this.card_list.findIndex(item => item.first_relation == this.hasCheckFirst);
        if (index > -1) {
          this.getNums(this.card_list[index].list, key);
        }
      } else {
        this.numObj = {};
        this.hasCheckFirst = '';
      }
    },
    updateSelectId({
      id,
      total
    }) {
      this.select_id = id;
      let key = this.hasCheckFirst;
      // 当数量大于0的时候存储 // 存储{ first_relation: select_id }
      if (total > 0) {
        this.select_total = total;
        sessionStorage.setItem(key, id);
      } else {
        sessionStorage.removeItem(key);
      }
    },
    // 获取卡包
    async getInfo() {
      let option = {
        page: this.page,
        page_size: this.page_size
      };
      const {
        status,
        data,
        message
      } = await getFirstLevelList(option);
      if (status != 0) {
        this.showError = true;
        this.showMessage = message || "暂无数据";
        return;
      } else if (data && data.total == 0) {
        this.total = data.total;
        this.loading = false;
        this.showError = false;
        this.card_list.splice(0, this.card_list.length);
        this.showMessage = "暂无数据";
        return;
      } else {
        this.showError = false;
        this.total = data.total;
        if (this.page == 1) {
          this.card_list.splice(0, this.card_list.length);
        }
        if (data.total > 0) {
          data.list.forEach(async item => {
            this.card_list.push({
              name: item.name,
              first_relation: item.first_relation,
              relation_type: item.relation_type,
              list: [],
              is_next: true //二级卡包
            });
            await this.getSecond(1, this.card_list.length - 1, item.first_relation);
          });
        }
        this.loading = false;
        this.is_next = data.is_next;
      }
    },
    async getSecond(page, index, first_relation) {
      let secondOption = {
        page: page,
        page_size: 30,
        first_relation: first_relation
      };
      const {
        status,
        data,
        message
      } = await getSecondLevelList(secondOption);
      if (status != 0) {
        mdToast(message);
        return;
      } else {
        this.card_list[index].is_next = data.is_next;
        this.card_list[index].list.push(...data.list);
        let key = first_relation;
        if (sessionStorage.getItem(key)) {
          if (sessionStorage.getItem('hasDeliverGood') == 'changeAddress') {
            this.select_id = sessionStorage.getItem(key);
          }
          await this.getNums(data.list, key);
        }
        // if (sessionStorage.getItem('hasDeliverGood') == 'changeAddress' && sessionStorage.getItem(key)) {
        //     this.select_id = sessionStorage.getItem(key)
        //     await this.getNums(data.list, key)
        // } else {
        //     sessionStorage.removeItem(key)
        // }
        if (data.is_next) {
          this.getSecond(page++, index);
        }
      }
    },
    // 获取每一项的数量
    async getNums(list, key) {
      if (!(list && list.length)) return this.numObj = {};
      let arr = list.map(item => item.activity_id);
      let {
        status,
        data
      } = await GetSelectActivityCardNums({
        activity_ids: arr.join(','),
        select_id: this.select_id
      });
      if (status == 0 && data.length) {
        let len = 0;
        data.forEach(item => {
          if (item.num == 0) len += 1;
          this.numObj[item.activity_id] = item.num;
        });
        if (len == data.length) {
          sessionStorage.removeItem(key);
        } else {
          this.hasCheckFirst = key;
        }
        this.openDeliver(list);
      }
    },
    onLoad() {
      if (!this.is_next) {
        this.loading = false;
        return false;
      }
      if (this.loading && this.page <= this.total) {
        // this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.page++;
          this.getInfo();
        }, 100);
      } else {
        this.loading = false;
      }
    },
    goRule() {
      this.$router.push(`/luckycards/container_rule`);
    },
    // 跳转卡包详情
    async goCardDetail(item, first_relation, length, relation_type) {
      if (this.hasCheckFirst && this.hasCheckFirst != first_relation) {
        mdToast('暂不支持跨店项目一起发货');
        return false;
      }
      this.canChooseMore = length > 1;
      this.hasCheckFirst = first_relation;
      this.second_relation = item.second_relation;
      this.activity_name = item.activity_name;
      this.activity_id = item.activity_id;
      this.relation_type = relation_type;

      // 获取运费模板详情
      if (this.template_map[item.template_id]) {
        this.template_info = this.template_map[item.template_id];
      } else {
        await this.getFreight(item.template_id);
      }
      this.showCards = true;
    },
    async getFreight(template_id) {
      if (this.relation_type == 1) return;
      const res = await getFreightTemplateInfo({
        id: template_id
      });
      if (res.status == 0) {
        this.template_info = JSON.parse(res.data.extract_postage_detail);
        this.template_info_list = JSON.parse(res.data.extract_postage_detail_list);
        this.template_map[template_id] = JSON.parse(res.data.extract_postage_detail);
      } else {
        this.template_info = {};
        this.template_info_list = {};
        mdToast(res.message);
      }
    },
    open() {
      if (this.select_total == 0 && this.card_total == 0) {
        mdToast('请选择要发货的卡包');
        return false;
      }
      this.deliverVisible = true;
    },
    hideCardDetail() {
      this.cardDetailVisible = false;
      this.card_detail_list = [];
    },
    goOrderList(item) {
      if (item.status == 1) {
        if (sessionStorage.getItem("order_id_list")) {
          sessionStorage.removeItem("order_id_list");
        }
        if (sessionStorage.getItem("activity_ids")) {
          sessionStorage.removeItem("activity_ids");
        }
        this.$router.push({
          path: "/luckycards/container_order_list",
          query: {}
        });
      }
    },
    // 获取默认地址接口
    async getAddress() {
      const res = await getAddressList({
        user_id: this.userId
      });
      if (res.status == 0 && res.data.length) {
        this.addressList = res.data;
        this.addressItem = this.addressList[0];
        this.address_loc = this.addressList[0].loc;
        if (sessionStorage.getItem('address')) {
          let item = JSON.parse(sessionStorage.getItem('address'));
          let index = this.addressList.findIndex(ele => ele.id == item.id);
          if (index > -1) {
            this.addressItem = item;
            this.address_loc = item.loc;
          }
        }
      }
    },
    // 默认打开-申请发货弹窗
    async openDeliver(list) {
      if (this.addressItem && sessionStorage.getItem('hasDeliverGood') == 'changeAddress') {
        await this.getFreight(list[0].template_id);
        this.deliverVisible = true;
        sessionStorage.removeItem('hasDeliverGood');
      }
    },
    cancelCheck() {
      this.select_id = '';
      this.activity_id = '';
      let key = this.hasCheckFirst;
      sessionStorage.removeItem(key);
      this.hasCheckFirst = '';
      this.numObj = {};
    }
  }
};
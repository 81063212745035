import { env } from "md-base-tools/env";
import { mapState } from "vuex";
import Error from "@@/common/Error.vue";
import { getThreeLevelList, getLevelList, SelectCardDraft, SelectCardDraftMerge } from "@/api";
import { Search, List, Icon } from 'vant';
import BigImage from '@@/luckycards/bigImage.vue';
import { mdToast } from "../../utils/tools";
export default {
  props: ['show',
  //展示
  'second_relation',
  //id
  'activity_name',
  //活动名称
  'activity_id',
  //活动id
  'select_id',
  //已选id
  'canChooseMore',
  //是否可继续选择
  'card_total',
  //已选数量
  'relation_type', 'template_info' // 运费模板
  ],
  components: {
    Error,
    Search,
    List,
    BigImage,
    Icon
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    showCards: {
      get() {
        return Boolean(this.show);
      },
      set(val) {
        this.$emit('closeDetail', val);
      }
    },
    selectId: {
      get() {
        return this.select_id;
      },
      set(val) {
        this.$emit('updateSelectId', {
          id: val,
          total: this.select_total
        });
      }
    },
    draft_total() {
      return this.select_total || this.card_total;
    },
    postage_detail() {
      // 运费详情
      if (this.template_info.fee > 0) {
        if (this.template_info.card_number == 0) {
          return `¥${this.template_info.fee}`;
        } else {
          if (this.select_total < this.template_info.card_number) {
            return `¥${this.template_info.fee} (满${this.template_info.card_number}张包邮)`;
          } else {
            return '包邮';
          }
        }
      } else {
        return '包邮';
      }
    }
  },
  data() {
    return {
      env,
      showMessage: "",
      loading: true,
      card_name: '',
      level_name: '全部',
      nav: [{
        level_name: '全部'
      }],
      card_detail_list: [],
      // 显示卡片的列表
      page: 1,
      timer: null,
      total: 0,
      page_size: 20,
      is_next: false,
      bigImageVisible: false,
      arrival_time: '',
      bigInitIndex: 0,
      img_list: [],
      closeIcon: 'https://p6.moimg.net/path/dst_project/1063753/202211/0310/4400/22110344008lmwGOxRgXAKv73Jdjvrbd12V0p5j7.png',
      select_total: 0,
      // 选中数量
      checkLoading: false,
      draft_select_id: '',
      //草稿id
      showTotal: true //
    };
  },
  methods: {
    async open() {
      this.page = 1;
      this.card_detail_list = [];
      this.card_name = '';
      this.level_name = '';
      this.draft_select_id = '';
      this.select_total = this.card_total;

      // 获取分类
      await this.getLevelListInfo();
      // 获取卡包详情
      await this.getInfo();
    },
    closeCards() {
      if (this.checkLoading) return this.showCards = false;
    },
    // 继续选择
    async continueCards() {
      if (this.checkLoading) return;
      if (this.relation_type != 1) {
        await this.mergeDraft();
      }
      this.showCards = false;
    },
    // 搜索
    async onSearch() {
      await this.getInfo(1);
    },
    async onClickButton() {
      await this.getInfo(1);
    },
    cancelCheck() {
      this.select_total = 0;
      this.selectId = '';
      this.draft_select_id = '';
      this.showTotal = false;
      this.card_detail_list.map(ele => {
        ele.select = false;
      });
    },
    // 卡片选择交互
    async checkCard({
      item,
      index,
      type
    }) {
      this.showTotal = true;
      if (this.checkLoading || this.relation_type == 1) return;
      this.checkLoading = true;
      let option = {};
      if (type == 'all') {
        // 全选
        let select_all = {
          level_name: this.level_name == '全部' ? '' : this.level_name,
          second_relation: this.second_relation,
          card_name: this.card_name
        };
        option = {
          select_id: this.selectId,
          draft_select_id: this.draft_select_id,
          cancel: 0,
          // 是否全选
          select_all_json_str: JSON.stringify(select_all)
        };
      } else {
        if (!item.can_deliver_goods) {
          return this.checkLoading = false;
        }
        // 单选
        let select_one = {};
        if (item.select) {
          select_one.cancel_order_id = item.card_order_id;
        } else {
          select_one.select_order_id = item.card_order_id;
        }
        option = {
          select_id: this.selectId,
          draft_select_id: this.draft_select_id,
          select_one_json_str: JSON.stringify(select_one)
        };
      }
      option.activity_id = this.activity_id;
      const {
        status,
        message,
        data
      } = await SelectCardDraft(option);
      this.checkLoading = false;
      if (status == 0) {
        if (this.showCards) {
          this.select_total = data.select_total;
          this.draft_select_id = data.draft_select_id;
          if (type == 'all') {
            this.card_detail_list.map(ele => {
              ele.select = true;
            });
          } else {
            this.card_detail_list[index].select = !this.card_detail_list[index].select;
          }
        }
      } else {
        mdToast(message || '选择失败！');
      }
    },
    // 草稿合并
    async mergeDraft() {
      if (!this.draft_select_id) return;
      const {
        status,
        data
      } = await SelectCardDraftMerge({
        activity_id: this.activity_id,
        select_id: this.select_id,
        draft_select_id: this.draft_select_id
      });
      if (status == 0) {
        this.selectId = data.select_id;
        this.select_total = data.total;
      }
    },
    // 获取卡包
    async getInfo(lockPage) {
      let endPage = lockPage ? lockPage : this.page;
      let option = {
        card_name: this.card_name,
        level_name: this.level_name == '全部' ? '' : this.level_name,
        second_relation: this.second_relation,
        page: endPage,
        page_size: this.page_size,
        select_id: this.selectId,
        draft_select_id: this.draft_select_id
      };
      const {
        status,
        data,
        message
      } = await getThreeLevelList(option);
      if (status != 0) {
        this.showMessage = message || "暂无数据";
        return;
      } else if (data && data.total == 0 && data.list && data.list.length == 0 && this.page == 1) {
        this.total = data.total;
        this.loading = false;
        this.card_detail_list.splice(0, this.card_detail_list.length);
        this.showMessage = "暂无数据";
        return;
      } else {
        this.total = data.total;
        if (this.page == 1) {
          this.card_detail_list.splice(0, this.card_detail_list.length);
          this.arrival_time = data.list[0].arrival_time;
        }
        this.card_detail_list.push(...data.list);
        this.loading = false;
        this.is_next = data.is_next;
      }
    },
    onLoad() {
      if (!this.is_next) {
        this.loading = false;
        return false;
      }
      if (this.loading && this.card_detail_list.length < this.total) {
        this.loading = false;
        this.timer;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.page++;
          this.getInfo();
        }, 100);
      } else {
        this.loading = false;
      }
    },
    // 切换tab
    changeNav(status) {
      this.level_name = status;
      this.card_detail_list.splice(0, this.card_detail_list.length);
      this.page = 1;
      this.loading = true;
      this.getInfo(1);
    },
    showBigImage(index) {
      this.img_list = [];
      this.card_detail_list.forEach(ele => {
        ele.showBackImg = false;
        this.img_list.push(ele);
      });
      this.bigInitIndex = index;
      this.bigImageVisible = true;
    },
    // 获取分类名称
    async getLevelListInfo() {
      const {
        status,
        data,
        message
      } = await getLevelList({
        id: this.activity_id,
        source_code: 1
      });
      if (status != 0) {
        mdToast(message);
      } else {
        if (data && data.length) {
          this.nav = [{
            level_name: '全部'
          }, ...data];
        }
      }
    },
    // 申请发货
    async deliver() {
      if (this.relation_type != 1) {
        await this.mergeDraft();
      }
      if (this.showTotal && this.select_total > 0) {
        this.showCards = false;
        this.$emit('open');
      } else {
        return mdToast('请选择要发货的卡牌');
      }
    }
  }
};